/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Box, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import SEO from '../components/Seo'
import PageLayout from '../components/PageLayout'
import BannerWrapper from '../components/BannerWrapper'
import BackButton from '../components/BackButton'
import TextBlock from '../components/TextBlock'
import Banner from '../components/Banner'
import IconText from '../components/IconText'
import SummaryRow from '../components/SummaryRow'
import FreeTrialBanner from '../components/FreeTrialBanner'
import { getClientsRowData } from '../pageData/clients_data'

const Scheduling = () => {
  const { t } = useTranslation()
  const rowData = getClientsRowData(t) || []
  return (
    <PageLayout>
      <SEO title="Clients" />
      <BannerWrapper>
        <BackButton />
        <Box p={[3, 3, 4, 4]}>
          <TextBlock
            heading={t('FeaturePage:featureClients:heading')}
            headingAsH1={true}
            textBeforeLink={t('FeaturePage:featureClients:detail')}
          />
        </Box>
        <Banner
          imageName="feature-woman-ladder"
          imageAlt={t('ImageAlts:clientsPageAlt1')}
          textOrientation="left"
        >
          <TextBlock
            heading={t('ClientsPage:firstBannerHeading')}
            textBeforeLink={t('ClientsPage:firstBannerDetail')}
          />
        </Banner>
        <Banner
          imageName="tablet-screen-clients-list"
          imageAlt={t('ImageAlts:clientsPageAlt2')}
          textOrientation="right"
        >
          <Box sx={{ flexDirection: 'colum' }}>
            <TextBlock heading={t('Global:howItWorks')} />
            <IconText text={t('ClientsPage:listItem1')} />
            <IconText text={t('ClientsPage:listItem2')} />
            <IconText text={t('ClientsPage:listItem3')} />
            <Box sx={{ paddingTop: 4 }}>
              <TextBlock
                heading={t('Global:youCanAlso')}
                variant="detailBold"
              />
            </Box>
            <IconText text={t('ClientsPage:listItem4')} />
            <IconText text={t('ClientsPage:listItem5')} />
            <IconText text={t('ClientsPage:listItem6')} />
          </Box>
        </Banner>
      </BannerWrapper>
      <SummaryRow summaryData={rowData} />
      <FreeTrialBanner />
    </PageLayout>
  )
}

export default Scheduling
